import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Demo from '../../components/Demo';
import saddleProps from '../../components/postc/saddle';
import fpProps from '../../components/postc/FM';
import { LazyPlot } from '../../components/Plotly';
import { Imb, Ptd } from '../../components/PageTitle/styles';
import triModalProps from '../../components/postc/triModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import "katex/dist/katex.min.css";
import { InlineMath, BlockMath } from 'react-katex';
export const _frontmatter = {
  "title": "Hello World!",
  "date": "2020-10-09T00:00:00.000Z",
  "author": "Armando Medina",
  "image": "../../images/javascript.jpg",
  "tags": ["Coding", "Blog", "Desiderata"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div style={{
      padding: '10px 30px',
      backgroundColor: 'orange',
      margin: '1em'
    }}>
    </div>
    <h1>{`Sobre el proyecto`}</h1>
    <div style={{
      padding: '10px 30px',
      backgroundColor: 'orange',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 10px',
      backgroundColor: 'red',
      margin: '1em'
    }}>
    </div>
    <blockquote>
      <h1 parentName="blockquote">{`El ocio no me deja tiempo para nada`}</h1>
      <ul parentName="blockquote">
        <li parentName="ul">{`El Cuarteto de Nos`}</li>
      </ul>
    </blockquote>
    <p>{`x`}</p>
    <h2>{`Naturalmente, en esta primera entrada del blog, se esperarían algunas palabras acerca de los motivos que han llevado a su creación, le ahorrare tiempo al lector: no existe motivo en particular, para mi empezó como un modo de invertir mi tiempo libre (!) en aprender algunas cosas nuevas.`}</h2>
    <h2>{`El blog ha sido creado utilizando Javascript (JSX) y varias herramientas código-libre, tantas, que mencionarlas todas tomaría demasiado tiempo, pero entre las principales figuran:`}</h2>
    <div style={{
      padding: '10px 30px',
      backgroundColor: 'red',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: 'blue',
      margin: '1em'
    }}>
    </div>
    <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
        
      <h1> React </h1>
    </a>
    <div style={{
      padding: '10px 30px',
      backgroundColor: 'blue',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#8b32a8',
      margin: '1em'
    }}>
    </div>
    <a href="https://www.gatsbyjs.com/about/" target="_blank" rel="noopener noreferrer">
        
      <h1> Gatsby </h1>
    </a>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#8b32a8',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#35f2d0',
      margin: '1em'
    }}>
    </div>
 <a href="https://github.com/about" target="_blank" rel="noopener noreferrer">
        
      <h1> GitHub </h1>
    </a>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#35f2d0',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#f5e042',
      margin: '1em'
    }}>
    </div>
    <blockquote>
      <h1 parentName="blockquote">{`What's gucci my gamer?`}</h1>
      <ul parentName="blockquote">
        <li parentName="ul">{`PewDiePie`}</li>
      </ul>
    </blockquote>
    <h2>{`Si planeaba invertir las horas que implica aprender algo nuevo, el blog que crearía tenía que ser  `}<strong parentName="h2">{`bueno`}</strong>{` , es decir, este debía cumplir con algunas características completamente arbitrarias y sin motivación alguna que a continuación exploraremos ...`}</h2>
    <blockquote>
      <h2 parentName="blockquote">{`x^2-y^2=0`}</h2>
      <p parentName="blockquote">{`2=0`}</p>
    </blockquote>
    <h2>{`Inevitablemente, si pretendía crear un blog incluyente, este debería ser tal, que se permitiera el uso de lenguajes históricamente marginados y sistemáticamente excluidos de tantos foros de discusión, por supuesto, ilustre lector, estamos hablando de`}</h2>
    <BlockMath math={'\\LaTeX'} mdxType="BlockMath" />
    <BlockMath math={`\\oint_{C}(L d x+M d y)=\\iint_{D}\\left(\\frac{\\partial M}{\\partial x}-\\frac{\\partial L}{\\partial y}\\right) d x d y`} mdxType="BlockMath" />
    <BlockMath math={`-\\frac{\\hbar^{2}}{2} \\sum_{n=1}^{N} \\frac{1}{m_{n}} \\frac{\\partial^{2}}{\\partial x_{n}^{2}} \\psi\\left(x_{1}, x_{2}, \\ldots, x_{N}\\right)+V\\left(x_{1}, x_{2}, \\ldots, x_{N}\\right) \\psi\\left(x_{1}, x_{2}, \\ldots, x_{N}\\right)=E \\psi\\left(x_{1}, x_{2}, \\ldots, x_{N}\\right)`} mdxType="BlockMath" />
    <BlockMath math={`R=\\left[\\begin{array}{ccc}
\\cos \\theta+u_{x}^{2}(1-\\cos \\theta) & u_{x} u_{y}(1-\\cos \\theta)-u_{z} \\sin \\theta & u_{x} u_{z}(1-\\cos \\theta)+u_{y} \\sin \\theta \\\\
u_{y} u_{x}(1-\\cos \\theta)+u_{z} \\sin \\theta & \\cos \\theta+u_{y}^{2}(1-\\cos \\theta) & u_{y} u_{z}(1-\\cos \\theta)-u_{x} \\sin \\theta \\\\
u_{z} u_{x}(1-\\cos \theta)-u_{y} \\sin \\theta & u_{z} u_{y}(1-\\cos \\theta)+u_{x} \\sin \\theta & \\cos \\theta+u_{z}^{2}(1-\\cos \\theta)
\\end{array}\\right]`} mdxType="BlockMath" />
    <h2>{`Note que `}<u>{`NO`}</u>{` se trata de simples imágenes, pruebe esto copiando y pegando cualquiera de las ecuaciones.`}</h2>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#f5e042',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#f542b6',
      margin: '1em'
    }}>
    </div>
    <blockquote>
      <h1 parentName="blockquote">{`De la vista nace el amor`}</h1>
      <p parentName="blockquote">{`-Anónimo`}</p>
    </blockquote>
    <h2>{`También quería poder presentar gráficas, interactivas, tanto en 2d como en 3d, en seguida algunos ejemplos:`}</h2>
    <p><em parentName="p">{`todo se visualiza mejor en pantallas grandes btw`}</em></p>
    <LazyPlot {...saddleProps} mdxType="LazyPlot" />
    <p><em parentName="p">{`Ya la habiamos visto, se trata de $x^2-y^2=0$`}</em></p>
    <LazyPlot {...fpProps} mdxType="LazyPlot" />
    <p><em parentName="p">{`El zoom en celulares aun da algunos problemas, tap con dos dedos para zoom-out..`}</em></p>
    <LazyPlot {...triModalProps} mdxType="LazyPlot" />
    <BlockMath math={`\\begin{array}{l}
\\exp \\left[-\\frac{1}{20}\\left(x^{2}+y^{2}\\right)\\right]+\\cos \\left\\{-\\frac{1}{10}\\left[(x-10)^{2}+y^{\\pi}\\right]\\right \\}+\\sin \\left\\{-\\frac{1}{10}\\left[(x-7)^{e}+\\right.\\right. \\\\
\\left.\\left.(y-7)^{2}\\right]\\right \\}
\\end{array}`} mdxType="BlockMath" />
    <p><em parentName="p">{`¿Por qué no?`}</em></p>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#f542b6',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#42f590',
      margin: '1em'
    }}>
    </div>
    <blockquote>
      <h1 parentName="blockquote">{`Silence, like a cancer, grows.`}</h1>
      <p parentName="blockquote">{`Disturbed (The Sound Of Silence)`}</p>
    </blockquote>
    <h2>{`Uno de mis hobbies implica trabajar con audio, ¿Cómo podría faltar el sonido?`}</h2>
    <h2>{`Prueba darle varios clicks a esta caricatura de transformaciones continuas ( i.e Homotopías) que parece una cebolla :`}</h2>
    <Demo mdxType="Demo" />
    <p><em parentName="p">{`otro de mis hobbies son los dank memes`}</em></p>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#42f590',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#fc1c03',
      margin: '1em'
    }}>
    </div>
    <blockquote>
      <h1 parentName="blockquote">{`Hello darkness, my old friend`}</h1>
      <p parentName="blockquote">{`Disturbed (The Sound Of Silence)`}</p>
    </blockquote>
    <h2>{`Si combinamos esto con varias horas de buscar soluciones en StackOverflow obtenemos el Dark Mode, prueba darle click al sol (luna) de la parte superior izquierda de esta pagina`}</h2>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#fc1c03',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#f5d742',
      margin: '1em'
    }}>
    </div>
    <blockquote>
      <h1 parentName="blockquote">{`Don't jump to conclusions`}</h1>
      <p parentName="blockquote">{`A loser`}</p>
    </blockquote>
    <h2>{`Siempre he aborrecido el tener que scroll-down 3 metros para encontrar la información que me interesa de un artículo, por eso, he agregado una tabla de contenidos en cada una de las páginas de este blog. Si lees desde tu celular(tablet) la puedes encontrar en la parte inferior izquierda de tu pantalla.`}</h2>
    <p>{`¿Lectura aburrida? Puedes regresar el átomo a su tamaño original arrastrándolo varias veces.`}</p>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#f5d742',
      margin: '1em'
    }}>
    </div>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#03fcfc',
      margin: '1em'
    }}>
    </div>
    <h2>{`Finalmente, es esencial poder colocar videos que puedan ayudarnos en nuestra exposicion, de YT y de ... otros sitios.`}</h2>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "57.14285714285714%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/bMzEClKWUxc?start=687",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <p><em parentName="p">{`tbh lograr esto fue trivial, lo no tan trivial fue hacer que fueran responsive...`}</em></p>
    <div style={{
      padding: '10px 30px',
      backgroundColor: '#03fcfc',
      margin: '1em'
    }}>
    </div>
    <h2>{`Así daré por concluida esta breve introducción, conforme vaya aprendiendo (e implementando) más funcionalidades iré actualizando este post, ya tengo varias ideas en mente (y en proceso) pero bienvenidas sean las sugerencias en los comentarios...`}</h2>
    <p><em parentName="p">{`miren nomas esa https encryption...`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      